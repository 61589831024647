.accordion {
  margin: 0 auto 30px;
  
  &__block {    
    border-bottom: 1px solid $blue;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  &__title {
    position: relative;
    padding: 10px 40px 10px 20px;
    font-size: 24px;
    line-height: 34px;
    font-weight: $font-weight--bold;
    color: $blue;
    cursor: pointer;
    @include bp('max', 'sm') {
      padding: 15px 40px 15px 20px;
      font-size: 16px;
      line-height: 20px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      display: block;
      width: 36px;
      height: 36px;
      background: url('/static/images/accordion-icon.png') no-repeat center center;
      background-size: cover;
      transition: transform 0.3s;
    }
    &.active {
      background-color: $alpha-blue;
      &::after {
        transform: scaleY(-1);
      }
      & + .accordion__content {
        min-height: 200px;
        height: auto;
        padding-bottom: 20px;
        background-color: $alpha-blue;
      }
    }
  }

  &__content {
    min-height: 0;
    height: 0;
    padding: 0 40px 0 20px;
    border-top: 0;
    overflow: hidden;
    transition: min-height 0.3s;
    font-size: 16px;
    line-height: 24px;
  }
  &__list {
    @extend %unordered-list;
    margin-top: 0;
    li {
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0;
    }
    li::before {
      font-size: 20px;
      color: $green;
      vertical-align: -1px;
    }
  }
}
.conditions-to-treat {
  &__content {
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  &__footnote {
    font-size: 12px;
    margin-top: 5px;
  }
}
.external-modal {
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        text-align: center;
        height: 93%;
    }

    &__text {
        padding: 10px;
        color: #000;
    }

    &__link-wrapper {
        margin: 20px;
    }

    &__link {
        color: $blue;
        text-decoration: underline;
        padding: 10px 0;
    }

    &__footnote {
        font-size: 11px;
        padding: 10px;
        color: #000;
    }
}
.footer {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $black;

  // Needed to fake `position: sticky` on IE
  @include ie-only() {
    .sticky-isi-top-space & {
      $default-sticky-isi-height: 130px;
      $default-sticky-isi-y-padding: 10px;
      margin-top: 30px + $default-sticky-isi-height + ($default-sticky-isi-y-padding * 2);
    }
  }

  &__top {
    padding-left: 30px;
    padding-right: 30px;
  }
  &__list {
    margin: 0;
    padding: 0;
    &-item {
      list-style: none;
      margin: 0 0 30px; 
      padding: 0;
    }
    &-link {
      color: $black;
      font-size: 14px;
      text-decoration: none;
    }
  }
  &__logos {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    @include bp('max', '500px') { 
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
    }
    &-left {
      @include bp('max', '500px') { 
        margin-bottom: 30px;
      }
    }
    &-right {
      @include bp('max', '500px') { 
        margin-bottom: 10px;
      }
      &--accessibility {
        margin-right: 2rem;
        @include bp('max', '500px') {
          margin-right: 0.5rem;
        }
      }
    }
  }
  
  &__merck-logo-image {
    min-width: 0;
    width: 145px;
  }

  &__truste-link {
    text-decoration: none;
    & + * {
      margin-left: 5px;
    }

    > img {
      min-width: 0;
      width: 145px;
      max-width: 100%;
    }
  }

  &__bottom {
    margin-top: 10px;
  }
  &__paragraph-text {
    padding: 10px 30px;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    background: rgba($color: $blue, $alpha: 0.7);
    &:last-child {
      background: $blue;
    }
  }
}
.header {
  position: relative;
  height: 140px;
  // padding: 30px;
  background: $white;

  @include bp('max', 'md') {
    @include zIndex('header');
    position: sticky;
    top: 0;
    height: 70px;
    width: 100%;

  }



  &__logo-container {
    position: relative;
    // left;
    display: block;
    padding: 40px 30px 20px 70px;
    a {
      display: block;
      @include poly("max-width", (1025px: 200px, 1240px: 275px));

      @include bp('max', '1200px') {
        margin-left: -30px;
      }

      img {
        width: 100%;
        max-width: 300px;
      }
    }
    @include bp('max', 'md') {
      position: relative;
      width: 100%;
      height: 70px;
      margin: 0 auto;
      padding: 15px 0; 
      box-shadow: 0px -5px 10px 5px rgba(0,0,0,0.4);

      a {
        display: block;
        width: 170px;
        margin: 0 auto;
        padding: 0;
      }
    }
  }

  
  &__nav-toggle {
    display: none;
    @include bp('max', 'md') {
      display: block;
      border: 1px solid $blue;
      border-radius: 4px;
      background: $white;
      color: $blue;
      font-size: 12px;
      font-weight: $font-weight--bold;
      padding: 5px 10px;
      outline: none;
      &:hover {
        background: $blue;
        color: $white;
      }
    }
    &--wrapper {
      @include bp('max', 320px) {
        left: 12px !important;
      }
      @include bp('max', 'md') {
        position: absolute;
        left: 20px;
        top: 22px;
      }
    }
  }
  &__navicon--closed {
    display: inline;
  }
  &__navicon--opened {
    display: none;
  }

  // open mobile menu styles
  &.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: $white;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 10px;
      background-color: $blue;
      background-image: linear-gradient(to right, $blue, $green);
    }
    .header__nav-toggle {
      background: $blue;
      color: $white;
      &:hover {
        background: $white;
        color: $blue;
      }
    }
    .header__navicon--closed {
      display: none;
    }
    .header__navicon--opened {
      display: inline;
    }
    .primary-navigation__wrapper {
      display: block;
      background: $alpha-blue;
      padding: 20px;
    }
  }
}

.homepage {
  $homepage-right-padding: 90px;
  &__wrapper {
    position: relative;

    width: 100%;
    min-height: 600px;
    padding-right: $homepage-right-padding;
    background: url('/static/images/homepage-bg.jpg') no-repeat center center;
    background-size: cover;

    @include bp('max', 'md') {
      display: block;
      padding-right: 0px;
      min-height: auto;
      background: none;
    }

    // Keeps the 'her2fight--green' from visibly overflowing
    @include ie-only() {
      overflow: hidden;
    }
  }

  &__floated {
    // Importantly, *don't* include a clearfix. That causes even more issues on IE

    float: right;
    @include bp('max', 'md') {
      float: none;
    }
  }

  &__mobile-bg {
    @include bp('max', 'md') {
      position: relative;
      display: block;
      width: 100%;
      min-height: 58vw;
      background: url('/static/images/homepage-bg-mobile.jpg') no-repeat left top;
      background-size: cover;
    }
  }

  .headline-h1 {
    display: block;
    margin: 15px 0 0;

    @include poly("font-size", (320px: 22px, 425px: 28px, 1024px: 70px, 1025px: 44px));
    @include poly("width", (320px: 180px, 425px: 230px, 1024px: 570px, 1025px: 670px));

    color: $blue;
    font-weight: $font-weight--bold;
    @include bp('max', 'md') {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 1;
    }
  }
  &__her2fight--green {
    position: absolute;
    bottom: 116px;
    left: 0;
    display: block;
    width: 364px;
    height: 91px;
    background: #6dbc44;
    text-align: right;

    img {
      padding: 18px 12px 0 0;
    }

    // On IE, the main cards don't shrink 100% correctly, although it is close
    // We can keep all content unobscured by bumping this element over a bit.
    // It doesn't match the comp, but it is better than obscuring content.
    @include ie-only() {
      left: -30px;
    }

    @include bp('max', 'md') {
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      width: 260px;
      height: 74px;
      padding: 5px;
      text-align: center;
      img {
        width: auto;
        height: 62px;
        padding: 10px 0 0;
      }
    }

  }
  &__copy {
    position: relative;
    max-width: 670px;
    margin: 0;
    color: $black;
    font-size: 18px;
    line-height: 26px;
    @include bp('max', 'md') {
      max-width: 100%;
      padding: 10px;
    }
  }
  &__footnote {
    position: absolute;
    bottom: 5px;
    left: 35%;
    color: $white;
    font-size: 12px;
    @include bp('max', 'md') {
      position: absolute;
      left: auto;
      bottom: 80px;
      right: 0;
      width: 240px;
      text-shadow: 0px 0px 5px $black;
    }
  }

  &__cards {
    position: absolute;
    bottom: 45px;
    right: $homepage-right-padding;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-end;
    height: 337px;

    @include poly("width", (1025px: 565px, 1440px: 965px));

    @include bp('max', 'md') {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
      height: auto;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content:center;
    }
  }
  &__card {
    display: block;

    @include poly("padding-top", (1025px: 12px, 1440px: 27px));
    @include poly("padding-left", (1025px: 14px, 1440px: 24px));
    padding-right: 8px;

    text-decoration: none;
    color: $white;
    @include poly("font-size", (1025px: 24px, 1440px: 30px));
    line-height: 1.5;
    font-weight: $font-weight--medium;
    overflow: hidden;
    background-size: cover;

    @include bp('max', 'md') {
      font-size: 18px;
      padding-right: 4px;
      padding-top: 17px;
      line-height: 24px;
    }

    @include bp('max', '340px') {
      font-size: 16px;
    }
  }

  $card-border-radius: 30px;

  $row-1-max-width: 402px;
  $row-1-min-width: 300px;
  $row-1-max-height: 160px;
  $row-1-min-height: $row-1-min-width / ($row-1-max-width / $row-1-max-height); // 402 / 160 = 300 / x -> solve for x

  &__card-1 {
    @include poly("width", (1025px: $row-1-min-width, 1440px: $row-1-max-width));
    @include poly("height", (1025px: $row-1-min-height, 1440px: $row-1-max-height));

    margin-right: 12px;
    margin-bottom: 13px;
    background: url('/static/images/home-cards-d-1.png') no-repeat center center;
    border-top-left-radius: $card-border-radius;

    @include bp('min', 'lg') {
      &:hover {
        background-image: url('/static/images/home-cards-d-1-hover.png');
      }
    }

    @include bp('max', 'md') {
      width: 100%;
      height: 66px;
      margin-right: 0;
      margin-bottom: 8px;
      background-image: url('/static/images/home-cards-m-1.png');
      background-size: 100% 66px;
    }
  }

  &__card-2 {
    width: 251px;
    @include poly("height", (1025px: $row-1-min-height, 1440px: $row-1-max-height));

    margin-right: 0;
    margin-bottom: 13px;
    background: url('/static/images/home-cards-d-2.png');
    border-top-right-radius: $card-border-radius;

    @include bp('min', 'lg') {
      &:hover {
        background-image: url('/static/images/home-cards-d-2-hover.png');
      }
    }

    @include bp('max', 'md') {
      width: calc(60% - 8px);
      height: 128px;
      margin-right: 8px;
      margin-bottom: 8px;
      background-image: url('/static/images/home-cards-m-2.png');
      background-size: 100% 128px;
    }
  }

  $row-2-max-height: 160px;
  $row-2-min-height: 135px;

  &__card-3 {
    @include poly("width", (1025px: 190px, 1440px: 351px));
    @include poly("height", (1025px: $row-2-min-height, 1440px: $row-2-max-height));

    margin-right: 12px;
    background-image: url('/static/images/home-cards-d-3.png');
    border-bottom-left-radius: $card-border-radius;

    @include bp('min', 'lg') {
      &:hover {
        background-image: url('/static/images/home-cards-d-3-hover.png');
      }
    }

    @include bp('max', 'md') {
      width: 40%;
      height: 128px;
      margin-right: 0;
      margin-bottom: 8px;
      background-image: url('/static/images/home-cards-m-3.png');
      background-size: 100% 128px;
    }
  }

  &__card-4 {
    @include poly("width", (1025px: 210px, 1440px: 339px));
    @include poly("height", (1025px: $row-2-min-height, 1440px: $row-2-max-height));

    margin-right: 12px;
    background-image: url('/static/images/home-cards-d-4.png');

    @include bp('min', 'lg') {
      &:hover {
        background-image: url('/static/images/home-cards-d-4-hover.png');
      }
    }

    @include bp('max', 'md') {
      width: calc(60% - 8px);
      height: 92px;
      margin-right: 8px;
      background-image: url('/static/images/home-cards-m-4.png');
      background-size: 100% 92px;
    }
  }

  &__card-5 {
    @include poly("width", (1025px: 138px, 1440px: 251px));
    @include poly("height", (1025px: $row-2-min-height, 1440px: $row-2-max-height));

    background-image: url('/static/images/home-cards-d-5.png');
    border-bottom-right-radius: $card-border-radius;

    @include bp('min', 'lg') {
      &:hover {
        background-image: url('/static/images/home-cards-d-5-hover.png');
      }
    }

    @include bp('max', 'md') {
      width: 40%;
      height: 92px;
      background-image: url('/static/images/home-cards-m-5.png');
    }
  }

  // Keep this here so it cascades properly
  &__card {
    @include bp('max', 'md') {
      border-radius: 0;
    }
  }
}
.ontruzant-given {

  .feature {
    &__wrapper {
      display: flex;
      align-items: stretch;

      > * {
        flex: 1 1 0%;
      }

      @include bp('max', 'sm') {
        display: block;
      }
    }
    
    
    &__copy {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 35px;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight--regular;
      @include bp('max', '1025px') {
        font-size: 16px;
        line-height: 24px;
      }
      @include bp('max', 'sm') {
        max-width: 100%;
      }
    }
    &__text {
      margin: 0;
    }
    &__headline {
      font-size: 28px;
      line-height: 34px;
      color: $blue;
      @include bp('max', '1025px') {
        font-size: 24px;
        line-height: 34px;
      }

    }
    &__list {
      @extend %unordered-list;
      li::before {
        color: $green;
      }
      @include bp('min', '1201px') {
        margin-left: 20px;
        li {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 15px;
          padding-left: 20px;
          &::before {
            margin-left: -20px;
            margin-right: 12px;
          }
          ul {
            margin-left: 20px;
          }
        }
        li::before {
          font-size: 20px;
          color: $green;
          vertical-align: -1px;
        }
      }
    }

    &__figure {
      position: relative;

      .figure {
        &__wrapper {
          @include image();
          position: relative;
          // display: flex;

          @include bp('max', 'sm') {
            width: 100%;
            img {
              margin: 0 auto;
              width: 100%;
            }
          }
        }
        &__disclaimer {
          position: absolute;
          bottom: 20px;
          right: 25px;
          color: $white;
          font-size: 14px;
          line-height: 19px;
          font-weight: $font-weight--light;
          text-shadow: 0 2px 4px rgba($color: #000, $alpha: 0.5);
        }
        
      }
    }

  } //end of .feature


  &__treatment-items {
    
    background: rgba(0,148,199,0.05);
    .treatment-items {
      &__headline {
        margin: 0;
        display: block;
        width: 100%;
        padding: 25px 35px;
        font-size: 24px;
        line-height: 34px;
        color: $blue;
        background: $white;

      } 
      &__list {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 1050px;
        margin: 0 auto 40px;
        padding: 40px 20px 60px;

        .treatment-item {
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          justify-content: center;
          width: 180px;
          height: 140px;
          text-align: center;
          font-weight: $font-weight--medium;
          &__image {
            margin-bottom: 10px;
          }
          @include bp('max', 'sm') {
            width: 135px;
          }
          @include bp('max', '500px') {
            width: 160px;
          }
        }
      }
    }
  }

}

.isi {

	&__wrapper {
		@include bp('max', '1439px') {
			padding: 0 15px;
		}
	}
	&__heading {
		color: $green;

		&--isi {
			@include bp('max', 'md') {
				font-size: 22px;
				line-height: 24px;
			}
		}
	}
	&__copy {
		color: #000;

		@include bp('max', 'sm') {
			margin-top: 10px;
		}

		li, p {
			font-weight: $font-weight--light;
			font-size: 16px;
			line-height: 22px;
		}

		.headline-h3 {
			margin-bottom: 5px;
		}
		p + .headline-h3 {
			margin-top: 20px;
		}
		.subheadline {
			margin-top: 10px;
			margin-bottom: 5px;
		}
		.headline-h3 + .subheadline {
			margin-top: 20px;
		}
		.unordered-list {
			margin-top: 0;
		}
		.unordered-list li {
			margin-bottom: 5px;
		}
		.list-preheader {
			margin-top: 10px;
		}

		&--footnote {
			font-size: 12px !important;
			line-height: 16px !important;
			margin-top: 5px;
		}
	}

	&__link {
		color: #0094C7;
		text-decoration: none;
	}
}

.isi-page {
	.page-content {
		margin-top: 15px;
	}
	.headline {
		&__wrapper {
			min-height: 500px;
			background: url('/static/images/ontruzant-glance-bg.png') no-repeat top right;
			background-size: cover;

			@include bp('max', 'sm') {
				padding-bottom: 390px;
				background: url('/static/images/ontruzant-glance-bg-mobile.jpg') no-repeat bottom
					right;
			}

			@include bp('max', '365px') {
				padding-bottom: 355px;
			}
		}
		&__title {
			@include bp('max', 'md') {
				max-width: 625px;
			}
		}

		&__copy {
			margin-left: 0;
			max-width: 625px;
			@include bp('max', 'md') {
				max-width: 625px;
			}
		}

		&__disclaimer {
			position: absolute;
			bottom: 40px;
			right: 400px;
			font-size: 14px;
			line-height: 19px;
			font-weight: $font-weight--light;

			@include bp('max', 'sm') {
				color: $white;
				text-shadow: 0 2px 4px rgba($color: #000, $alpha: 0.5);
				bottom: 20px;
				right: 45px;
			}
		}
	}
}

.isi-safety-overlay {
	&__wrapper {
		@include bp('max', '1439px') {
			padding: 0 15px;
		}
	}

	&__scroll {
		margin-top: 22px;

		@include bp('min', 'md') {
			margin-top: 10px;
		}
	}

	&__scroll-text {
		text-align: center;
		color: $blue;
		text-decoration: underline;
	}
}
.modal {
  // Defaults to hidden
  display: none;
  background: rgba($color: $white, $alpha: 0.4);

  // Pad the top header and bottom sticky (loosely)
  padding: 5px;

  $inner-padding--desktop: 30px;
  $inner-padding--mobile: 20px;

  @include zIndex('modal');

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @include bp('min', '1025px') {
    // Pad the top header + main-nav and bottom sticky (loosely)
    padding: 30px;
  }

  &__header,
  .isi__header {
    background: $green;
    padding: 0 #{15px + 15px};
    margin: 0 -15px;
    .headline-h2 {
      color: $white;
    }
    @include bp('max', '1025px') {
      padding: 10px 35px 10px 15px;
    }
  }

  &__header,
  .isi-safety-overlay__header {
    background: $green;
    padding: 10px #{20px + 20px};
    margin: 0 -15px;
    .headline-h2 {
      color: $white;
      @include bp('min', '1025px') {
        line-height: 35px;
      }
    }
    @include bp('max', '1025px') {
      padding: 10px 35px 10px 15px;
    }
  }

  &__header,
  .external-modal__header {
    background: $green;
    padding: 0 #{15px + 15px};
    @include bp('max', '1025px') {
      padding: 10px 35px 10px 15px;
    }
  }

  // Override padding / margin above, should only apply to '.isi__header' :shrug:
  &__header {
    padding: 0 15px;
    margin: 0;
    @include bp('max', '1025px') {
      padding: 10px 35px 10px 15px;
    }
  }

  &__content {
    padding: 0 15px 15px 15px; 
  }

  .isi__copy {
    padding: 0;

    @include bp('min', 'lg') {
      padding: 15px;
    }
  }

  &--open {
    display: block;
  }
  &__wrapper {
    display: block;
    margin: 0 auto;
    position: relative;
    border-radius: 5px;
    overflow-x: hidden;
    background: $white;
    max-height: 100%;
    max-width: 1150px;
    box-shadow: 0 0px 5px 0px rgba(0,0,0,0.2);
    
    @include ie-only() {
      // PDS-119 - Flexbox on this wrapper is causing issues.
      // Is flex even needed here though? Hard to see what problem it is solving
      display: block;
    }
  }
  &__container {
    display: block;
    margin: 0;
    position: relative;
    border-radius: 5px;
    overflow: auto;  

    &--no-flex {
    }
  }

  &__close-button {
    @include reset-button();
    position: absolute;
    top: 0px;
    right: 0px;

    width: 100%;
    height: 45px;
    @include zIndex('modalCloseBtn');
    cursor: pointer;

    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      display: block;
      width: 16px;
      height: 16px;
      background: url('/static/images/icon-plus.png') no-repeat center center;
      transform: translateY(-50%) rotate(45deg);
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }
  
  // custom modals styles
  
  // setting height for long modal copy to overflow-y:scroll
  // with still header to allow closing modal
  &[data-modal-id="isi-modal"] {
    .modal__wrapper,
    .modal__container {
      height: 100%;
    }
    .isi__wrapper {
      height: 100%;
      overflow: hidden;
    }
    .isi__copy {
      $modal-header-height-when-two-lines-on-mobile: 108px;
      height: calc(100% - #{$modal-header-height-when-two-lines-on-mobile});
      overflow: auto;
    }
  }

  &[data-modal-id="isi-safety-overlay-modal"] {
    .modal__wrapper,
    .modal__container {
      height: 100%;
      @include bp('min', '1025px') {
        height: 98%;
      }
    }
    .isi-safety-overlay__wrapper {
      height: 100%;
      overflow: hidden;
    }
    .isi__copy {
      height: calc(100% - 150px);
      @include bp('min', 'md') {
        height: calc(100% - 88px);
      }
      overflow: auto;
    }
  }

  &[data-modal-id="conditions-modal"] {
    @include bp('min', '1025px') {
      .modal__wrapper {
        top: 43%;
        transform: translateY(-50%);
      }
    }
    @include bp('max', 'md') {
      .modal__wrapper,
      .modal__container,
      .conditions-to-treat,
      .conditions-to-treat__wrapper {
        height: 100%;
      }
      .conditions-to-treat__content {
        height: 100%;
        overflow: hidden;
      }
      .modal__content {
        // modal header is 68px height when 2 line on mobile
        height: calc(100% - 68px);
        overflow: auto;
      }
    }
  }

  &[data-modal-id="external-modal"] {
    @include bp('min', '1025px') {
      .modal__wrapper {
        top: 43%;
        transform: translateY(-50%);
        max-width: 645px;
      }
    }
    @include bp('max', 'md') {
      .modal__wrapper,
      .modal__container,
      .external-modal,
      .external-modal__wrapper {
        height: 100%;
        overflow: hidden;
      }
    }
  }

}



.ontruzant-at-a-glance {

  .headline {
    &__wrapper {
      min-height: 500px;
      background: url('/static/images/ontruzant-glance-bg.png') no-repeat top right;
      background-size: cover;
      @include bp('max', 'sm') {
        padding-bottom: 390px;
        background: url('/static/images/ontruzant-glance-bg-mobile.jpg') no-repeat bottom right;
      }
      @include bp('max', '365px') {
        padding-bottom: 355px;
      }
    }
    &__title { 
      
      @include bp('max', 'md') {
        max-width: 625px;
      }
    }
    &__copy {
      margin-left: 0;
      max-width: 625px;
      @include bp('max', 'md') {
        max-width: 625px;
      }
    }
    &__disclaimer {
      position: absolute;
      bottom: 40px;
      right: 400px;
      font-size: 14px;
      line-height: 19px;
      font-weight: $font-weight--light;
      @include bp('max', 'sm') {
        color: $white;
        text-shadow: 0 2px 4px rgba($color: #000, $alpha: 0.5);
        bottom: 20px;
        right: 45px;

      }
    }
  }
}
.primary-navigation {
  @include bp('max', 'md') {
    height: 100%;
  }

  &__wrapper {
    @include bp('min', '1025px') {
      position: absolute;
      bottom: 25px;
      right: 0;
    }

    @include bp('max', 'md') {
      display: none;
      height: 60%;
      height: calc(65% - 36px);
    }
  }

  &__content {
    @include bp('max', '1025px') {
      height: 100%;
    }
  }

  &__list {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;

    @include bp('max', 'md') {
      display: none;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;

      .header.active & {
        display: flex;
      }
    }
  }

  &__list-item {
    &.active a {
      color: $blue;
      font-weight: $font-weight--bold;
    }

    & a:hover {
      color: $blue;
    }

    & + * {
      @include poly("margin-left", (375px: 0, 1025px: 30px, 1200px: 40px, 1440px: 70px));
    }

    @include bp('min', 'lg') {
      &:last-child {
        margin-right: 40px;
      }
    }

    @include bp('max', 'md') {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid $blue;
      &:last-child {
        border-bottom: 2px solid transparent;
      }
    }

    &--mobile-home {
      display: none;
      @include bp('max', 'md') {
        display: flex;
      }
    }
  }

  &__link {
    color: $black;
    text-decoration: none;
    font-weight: $font-weight--medium;
    font-size: 16px;

    @include bp('min', '1025px') {
      text-transform: uppercase;
      line-height: 20px;
    }

    @include bp('max', 'md') {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 16px;
        height: 14px;
        background: url('/static/images/icon-arrow-right.png') no-repeat center center;
        transform: translateY(-50%);
      }

      &.active {
        color: $blue;
        font-weight: $font-weight--bold;
      }
    }
  }
}

.sticky-isi {
	@include zIndex('stickyIsi');
	position: fixed;
	bottom: 0;
	max-width: $maxPageWidth;
	padding: 10px;
	transition: all 0.3s;
	transform: translateY(100%);
	opacity: 0;
	margin: 0 auto;
	visibility: hidden;
	
	&--visible {
		bottom: 0;
		opacity: 1;
		transform: translateY(0);	
		visibility: visible;
		position: sticky;

		@include ie-only() {
			position: static;

			.sticky-isi-top-space & {
				position: fixed;
			}
		}
	}

	&--display-none {
		display: none;
	}
	// .homepage + &--visible {
	// }
	
	&__wrapper {
		overflow: hidden;
		position: relative;
		max-width: 96%;
		// height: 130px;
		margin: 0 auto;
		background: $white;
		border-radius: 5px;
		box-shadow: 0 3px 12px -4px rgba(0,0,0,0.6);
		@include ie-only() {
			box-shadow: 0 3px 12px -4px rgba(0,0,0,0.6);
		}
		@include poly('height', (360px: 185px, 1100px: 150px, 1240px: 130px));
		@include bp('max', 'md') {
			max-width: 100%;
		}
	}

	&--header {
		width: 100%;
		padding: 5px 20px;
		background: $blue;
		text-align: left;

		.headline-h2 {
			position: relative;
			line-height: 45px;
			font-weight: $font-weight--bold;
			color: $white;
			margin: 0 auto;

			@include bp('max', 'md') {
				line-height: 30px;
			}

			@include poly("font-size", (320px: 14px, 375px: 18px, 1024px: 24px));

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0px;
				display: block;
				width: 16px;
				height: 16px;
				background: url('/static/images/icon-plus.png') no-repeat center center;
				transform: translateY(-50%);
			}

			@include bp('max', 'md') {
				&::after {
					content: '';
					position: absolute;
					top: 60%;
					right: -11px;
					display: block;
					width: 16px;
					height: 16px;
					background: url('/static/images/icon-plus.png') no-repeat center center;
					transform: translateY(-50%);
				}
			}
		}
	}
	&--copy {
		max-height: 90px;
		padding: 8px 20px;
		font-size: 15px;
		line-height: 20px;
		font-weight: $font-weight--bold;
		text-align: left;
		color: #000;
		overflow : hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		@include bp('max', 'md') {
			font-size: 16px;
			line-height: 24px;
			font-weight: $font-weight--bold;
			max-height: 107px;
		}
	}

	&__modal-trigger {
		position: relative;
		display: block;
    width: auto;
    height: auto;
    text-decoration: none;
	}
}

.support-and-coverage {


  &__headline {
    
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;

    @include bp('max', '767px') {
      flex-direction: column;
    }
    .headline {
      &__left {
        // display: flex;
        width: 60%;
        @include bp('max', '767px') {
          width: 100%;
        }
      }
      &__title {
        max-width: 100%;
        background-position: left center;
        background-size: 100% 116px;
        @include bp('max', '767px') {
          
        }
      }

      &__right {
        // display: flex;
        width: 435px;
        @include bp('max', '767px') {
          width: 100%;
        }
      }

      &__image {
        position: relative;
        @include image();
        @include bp('max', '767px') {
          img {
            width: 100%;
          }
        }
      }
      &__disclaimer {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: $white;
      }
    }
  }
  &__merck-access {
    .merck-access {
      &__header {
        padding: 35px;
      }
      &__title {
        margin-bottom: 15px;
        color: $blue;
      }
      &__description {
        font-size: 16px;
        line-height: 24px;
        font-weight: $font-weight--regular;
      }

      &__features {
        display: flex;
        align-items: flex-start;
        align-content: center;
        justify-content: space-around;
        padding: 60px;
        background: rgba($color: $green, $alpha: 0.10);
        @include bp('max', '767px') {
          flex-direction: column;
          align-items: center;
          padding: 60px 0;
        }
      }
      &__feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        max-width: 280px;
        padding: 20px;
        
        &-image {
          display: flex;
          align-items: flex-end;
          align-content: flex-end;
          justify-content: center;
          width: 100%;
          height: 120px;
          margin-bottom: 20px;
          img {
            width: auto;
            height: auto;
          }
        }
        &-copy {
          font-size: 18px;
          line-height: 24px;
          font-weight: $font-weight--medium;
          color: $black;
          text-align: center;

        }
      }

      &__buttons {
        position: relative;

      }
      &__button {
        position: relative;
        margin: 60px auto;
        display: block;
        width: 445px;
        min-height: 60px;
        @include bp('max', '767px') {
          max-width: 100%;
          width: auto;
          min-height: 50px;
          margin: 30px;
        }
        @include bp('max', 'xs') {
          margin: 30px 10px;
        }
      }
      &__btn {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 5px;
        background: $blue;
        font-size: 21px;
        line-height: 21px;
        font-weight: $font-weight--bold;
        color: $white;
        text-decoration: none;
        @include bp('max', '767px') {
          font-size: 14px;
          line-height: 18px;
        }

        &:hover {
          background: rgba($color: $blue, $alpha: 0.7);
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 25px;
          display: block;
          width: 33px;
          height: 29px;
          transform: translateY(-50%);
          background: url('/static/images/btn-arrow-right.png') no-repeat center center;
          @include bp('max', '767px') {
            right: 15px;
            transform: translateY(-50%) scale(0.8);
          }
        }
  
        &--next {}
        &--download {
          &::after {
            width: 29px;
            height: 41px;
            background-image: url('/static/images/btn-download.png');
            @include bp('max', '767px') {
              transform: translateY(-50%) scale(0.8);
            }
          }
        }
      }
    }
  }
}
.top-info-banner {

	&__wrapper {
		overflow-x: hidden;
		padding-bottom: 15px;
		margin-bottom: -15px;
		position: relative;
    z-index: 1;
	}
	&__content {
		background: $blue;
		color: $white;
		position: relative;
		padding: 5px 25px;
		box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
		@include ie-only() {
			box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
		}
		h2 {
			margin: 0;
		}
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 5px;

		}
		@include bp('max', '1025px') {
			padding-top: 10px;
		}
		@include bp('max', '375px') {
			padding-left:15px;
			padding-right:15px;
			.headline-h2 {
				font-size: 15px;
			}
		}
	}
	&__modal-trigger {
		position: relative;
		display: block;
		text-decoration: none;
		color: $white;
		.headline-h2 {
			position: relative;
			&::after {
				content: 'SEE MORE';
				position: absolute;
				font-size: 14px;
				font-weight: bold;
				top: 50%;
				right: 0;
				display: block;
				width: 90px;
				background: url('/static/images/icon-plus.png') no-repeat center right;
				transform: translateY(-50%);
			}
			@include bp('max', '1025px') {
				padding-right: 95px;
				&::after {
					content: 'SEE MORE';
					position: absolute;
					font-size: 14px;
					font-weight: bold;
					top: 50%;
					right: 0;
					display: block;
					width: 90px;
					background: url('/static/images/icon-plus.png') no-repeat center right;
					transform: translateY(-50%);
				}
			}
		}
	}
	&__copy {
		display: block;
		max-height: 60px;
		@include bp('min', 'md') {
			max-height: 50px;
		}
		margin-bottom: 5px;
		font-size: 17px;
		line-height: 24px;

		overflow : hidden;
		text-overflow: ellipsis;

		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		.homepage & {
			display: block;
			display: -webkit-box;
		}

		@include bp('max', '375px') {
			padding-right: 25px;
			font-size: 16px;
			line-height: 20px;
		}

		@include ie-only() {
			max-height: 51px;
		}
	}

}

.utility-navigation {
  position: absolute;
  top: 30px;
  right: 00px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;

  @include bp('max', 'md') {
    position: relative;
    top: auto;
    right: auto;
    display: none;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-around;
    text-align: left;
    padding: 20px 20px 30px;

    .header.active & {
      display: flex;
    }
  }

  &__wrapper {
    @include bp('max', 'md') {
      height: 30%;
      height: calc(35% - 35px);
    }
  }

  &__item {
    margin-right: 40px;

    &:last-child {
      margin-right: 40px;
    }

    @include bp('max', 'md') {
      margin-left: 0;
      text-align: left;
    }

    @include bp('min', 'lg') {
      // Comp is incorrect, but we can't change it so have to adjust it on the FE side
      // In HTML, the ordering is correct for _mobile_ so override it on desktop
      &--isi {
        order: 0;
      }
      &--pi {
        order: 1;
      }
      &--hcp {
        order: 2;
      }
    }

    a {
      text-decoration: none;
      color: $black;
      @include bp('max', 'md') {
        font-size: 16px;
        font-weight: $font-weight--medium;
        color: $blue;
      }
    }
  }
}

.her2plus {


  &__feature {
    position: relative;
    display: flex;
    background: $green;

    .feature {
      &__main-image {
        position: relative;
        width: 333px;

        @include image();
        @include bp('max', 'sm') {
          max-width: 146px;
          width: 146px;
          height: 213px;
          background: url('/static/images/her2plus-woman-mobile.jpg') no-repeat center center;
          background-size: cover;
          img {
            display: none;
          }
        }
        
        &-disclaimer {
          position: absolute;
          bottom: 20px;
          left: 10px;
          font-size: 14px;
          line-height: 19px;
          font-weight: $font-weight--light;
          color: $white;
          text-shadow: 1px 2px 2px rgba($color: #000, $alpha: 0.5);
          @include bp('max', 'sm') {
            bottom: 5px;
          }
        }

      }

      &__copy-wrapper {
        display: flex;
        align-items: center;
        padding: 5px 40px;
        @include bp('max', 'sm') {
          flex-direction: column;
          align-items: flex-start;
          max-width: 100%;
          height: 100%;
          padding: 20px 10px;
        }
      }
      &__copy-text {
        font-size: 56px;
        line-height: 52px;
        color: $white;
        @include bp('max', 'sm') {
          font-size: 26px;
          line-height: 34px;
        }
        @include bp('max', 'xs') {
          font-size: 20px;
          line-height: 24px;        
        }
      }
      &__copy-image {
        position: absolute;
        display: block;
        bottom: 20px;
        right: 20px;
        @include image();
        @include bp('max', '1200px') {
          max-width: 25%;
        }
        @include bp('max', 'sm') {
          right: auto;
          left: 156px;
          max-width: 200px;
        }

      }
    }
  }
}
.what-is-ontruzant {

  .feature {
    &__wrapper {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      background: rgba($color: $green, $alpha: 0.1);
      @include bp('max', 'sm') {
        flex-direction: column;
      }
    }
    
    
    &__copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 49%;
      padding: 35px;
      font-size: 18px;
      line-height: 28px;
      font-weight: $font-weight--regular;
      @include bp('max', 'sm') {
        max-width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__headline {
      color: $green;
      margin: 15px 0 0;
      @include bp('max', 'md') {
        font-size: 24px;
        line-height: 40px;
      }
    }
    &__list {
      @extend %unordered-list;
      margin-left: 20px;
      @include bp('max', '1025px') { 
        margin-left: 10px;
      }
      li {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
        @include bp('max', 'sm') {
          font-size: 16px;
          line-height: 24px;
        }
      }
      li::before {
        font-size: 20px;
        color: $green;
        vertical-align: -1px;
      }
    }

    &__figure {
      position: relative;
      display: flex;
      
      width: 50%;
      background: $blue;
      @include bp('max', 'sm') {
        width: 100%;
      }

      .figure {
        &__wrapper {
          @include image();
          position: relative;
          display: flex;
          img {
            align-self: center;
          }
          @include bp('max', 'sm') {
            width: 100%;
            img {
              margin: 0 auto;
            }
          }
        }
        &__disclaimer {
          position: absolute;
          top: 30px;
          left: 40px;
          color: $white;
          font-size: 24px;
          line-height: 36px;
          font-weight: $font-weight--medium;
          @include bp('max', 'sm') {
            top: 15px;
            left: 20px;
            font-weight: $font-weight--regular;
          }
          @include bp('max', 'xs') {
            font-size: 20px;
            line-height: 20px;
          }
        }
        &__caption {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          padding: 15px;
          background: $green;
          color: $white;
          font-size: 24px;
          line-height: 24px;
          font-weight: $font-weight--regular;
          text-align: center;
          @include bp('max', 'sm') {
            font-size: 16px;
            line-height: 20px;
          }
          @include bp('max', 'xs') {
            font-size: 13px;
            line-height: 20px;
            padding: 10px;
          }
        }
        
      }
    }

  }

}
