@import 'partials/*';
@import 'components/*';

html {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
}

*,
*:before,
*:after {
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

// Makes font smoother and crisp throughout
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

